<template>
  <div>
    <van-cell>
      <template #title>
        <div class="chart-title">7天预报</div>
      </template>
    </van-cell>
    <div class="forcast-weather-wrapper" v-show="locationdata">
      <div ref="echartsdiv" id="echartsdiv"></div>
      <div class="forcast-weather-list">
        <div
          class="weather-item"
          v-for="(item, index) in forcast7List"
          :key="index"
        >
          <span>{{
            dayjs(item.time).isToday()
              ? "今天"
              : cnWeek(dayjs(item.time).weekday())
          }}</span>
          <span>{{ dayjs(item.time).format("MM-DD") }}</span>
          <van-image width="30" height="30" :src="item.dayWeathericon" />
          <span>{{ item.dayPower === "9999" ? "-" : item.dayPower }}</span>
          <span>{{ item.dayDirect === "9999" ? "-" : item.dayDirect }}</span>
          <span>{{ item.dayWdesc === "9999" ? "-" : item.dayWdesc }}</span>

          <van-image
            width="30"
            height="30"
            :src="item.nightWeathericon"
            class="night-img"
          />
          <span>{{ item.nightWdesc === "9999" ? "-" : item.nightWdesc }}</span>
          <span>{{
            item.nightDirect === "9999" ? "-" : item.nightDirect
          }}</span>
          <span>{{ item.nightPower === "9999" ? "-" : item.nightPower }}</span>
        </div>
      </div>
    </div>
    <van-loading v-show="!locationdata" class="loading" />
  </div>
</template>
<script>
import { Image, Cell, Loading } from "vant"
import { get7days } from "@/service/forcastWeather"
import dayjs from "dayjs"
import weekday from "dayjs/plugin/weekday"
import isToday from "dayjs/plugin/isToday"
import isoWeek from "dayjs/plugin/isoWeek"
// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from "echarts/core"
// 引入线图图表，图表后缀都为 Chart
import { LineChart } from "echarts/charts"
// 引入提示框，标题，直角坐标系组件，组件后缀都为 Component
import { GridComponent } from "echarts/components"
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from "echarts/renderers"

// 注册必须的组件
echarts.use([LineChart, GridComponent, CanvasRenderer])
dayjs.extend(isoWeek)
dayjs.extend(isToday)
dayjs.extend(weekday)

export default {
  name: "ForcastSeven",
  data() {
    return {
      forcast7List: [],
      dayjs: dayjs,
      locationdata: null
    }
  },
  components: {
    VanImage: Image,
    VanCell: Cell,
    VanLoading: Loading
  },
  watch: {
    "$store.state.LocationMessage": function (newValue) {
      this.locationdata = newValue
      this.reset(newValue.lon, newValue.lat)
    }
  },
  created() {
    if (!this.$store.state.LocationMessage) {
      return
    }
    this.locationdata = this.$store.state.LocationMessage
    this.reset(
      this.$store.state.LocationMessage.lon,
      this.$store.state.LocationMessage.lat
    )
  },
  mounted() {
    this.initEcharts()
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
    handleWeather(data) {
      const timeline = []
      const dayTempList = []
      const nightTempList = []
      for (let i = 0; i < data.length; i++) {
        const element = data[i]
        timeline.push(element.time)
        dayTempList.push(
          parseFloat(element.dayTemp === "9999" ? "" : element.dayTemp)
        )
        nightTempList.push(
          parseFloat(element.nightTemp === "9999" ? "" : element.nightTemp)
        )
      }
      return { timeline, dayTempList, nightTempList }
    },
    setEchats(target, options) {
      target.setOption(options)
    },
    cnWeek(num) {
      switch (num) {
        case 1:
          return "周一"
        case 2:
          return "周二"
        case 3:
          return "周三"
        case 4:
          return "周四"
        case 5:
          return "周五"
        case 6:
          return "周六"
        case 0:
          return "周日"
        default:
          return null
      }
    },
    reset(lng, lat) {
      get7days(114, 26).then((res) => {
        if (res.code === 200) {
          this.forcast7List = res.data.weatherForecast
          const { timeline, dayTempList, nightTempList } = this.handleWeather(
            this.forcast7List
          )
          this.setEchats(this.myChart, {
            xAxis: {
              data: timeline
            },
            series: [
              {
                label: {
                  show: true,
                  position: "top",
                  fontSize: 16,
                  formatter: "{c}℃"
                },
                itemStyle: {
                  color: "#fcc370"
                },
                name: "日间温度",
                type: "line",
                data: dayTempList
              },
              {
                label: {
                  show: true,
                  position: "bottom",
                  fontSize: 16,
                  formatter: "{c}℃"
                },
                itemStyle: {
                  color: "#94ccf9"
                },
                name: "夜间温度",
                type: "line",
                data: nightTempList
              }
            ]
          })
        }
      })
    },
    initEcharts() {
      // 基于准备好的dom，初始化echarts实例
      const myChart = echarts.init(this.$refs.echartsdiv)
      this.myChart = myChart
      // 绘制图表
      myChart.setOption({
        grid: { left: 0, right: 0, bottom: 28 },
        xAxis: {
          type: "category",
          axisLine: {
            show: false
          },
          axisLabel: {
            show: false
          },
          axisTick: {
            show: false
          },
          data: []
        },
        yAxis: {
          axisLine: {
            show: false
          },
          axisLabel: {
            show: false
          },
          splitLine: {
            show: false
          }
        },
        series: []
      })
    }
  }
}
</script>
<style lang='scss' scoped>
$color: #001f90;
.chart-title {
  border-left: $color 5px solid;
  padding: 4px;
  font-size: 16px;
  font-weight: 800;
  margin-top: 16px;
}
.forcast-weather-wrapper {
  position: relative;
  width: 100%;
  overflow: auto;
  #echartsdiv {
    width: 421px;
    height: 120px;
    position: absolute;
    top: 160px;
  }
  .forcast-weather-list {
    display: flex;
    width: 560px;
    .weather-item {
      // width: 80px;
      width: 16vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      span {
        display: block;
        height: 30px;
        line-height: 30px;
      }
      .night-img {
        margin-top: 120px;
      }
    }
  }
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
}
</style>
