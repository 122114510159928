<template>
  <div class="wrapper">
    <van-nav-bar left-arrow left-text="返回" @click-left="onClickLeft">
      <template #title>
        <div class="position">
          <van-icon name="location-o" />
          <span v-if="$store.state.LocationMessage">
            <!-- {{ $store.state.LocationMessage.province }} -->
            {{ $store.state.LocationMessage.city }}
          </span>
          <span v-else> 请求定位中 </span>
        </div>
      </template>
    </van-nav-bar>
    <div class="content">
      <forcast-seven />
    </div>
  </div>
</template>
<script>
import { NavBar, Icon } from 'vant';
import ForcastSeven from '@/views/home/Forcast7';
export default {
  name: 'Warning',
  data() {
    return {};
  },
  components: {
    VanNavBar: NavBar,
    VanIcon: Icon,
    ForcastSeven
  },
  created() {},
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    }
  }
};
</script>
<style lang='scss' scoped>
$color: #001f90;
.wrapper {
  .content {
    padding: 0 8px;
  }
  .position {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
  }
}
</style>
